import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import * as fromGenerated from '../../_generated';

import { AuthActions } from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: fromGenerated.UserView | null;
  error: string | null;
}

export const initialState: AuthState = {
  user: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.loadUserSuccess, (state, { user }) => ({
    ...state,
    user: { ...user },
    error: null,
  })),
  on(AuthActions.loadUserFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(AuthActions.refreshTokensSuccess, (state) => ({
    ...state,
    error: null,
  })),
  on(AuthActions.refreshTokensFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer,
  extraSelectors: ({ selectAuthState }) => ({
    selectIsAuthenticated: createSelector(selectAuthState, (state) => {
      return !!(state.user && !state.error);
    }),
  }),
});
