import { createActionGroup, emptyProps, props } from '@ngrx/store';

import * as fromGenerated from '../../_generated';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    Login: emptyProps(),
    'Load User': emptyProps(),
    'Load User Success': props<{ user: fromGenerated.UserView }>(),
    'Load User Failure': props<{ error: string }>(),
    'Start Refresh Tokens Timer': emptyProps(),
    'Refresh Tokens': emptyProps(),
    'Refresh Tokens Success': emptyProps(),
    'Refresh Tokens Failure': props<{ error: string }>(),
    Logout: emptyProps(),
  },
});
