import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import * as fromDesignSystem from '@serious-system';
import { switchMap } from 'rxjs';

import { AuthActions } from './store/auth.actions';
import { authFeature, AuthState } from './store/auth.reducer';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    RouterModule,
    fromDesignSystem.ButtonDirective,
    fromDesignSystem.UseSocialIconDirective,
    fromDesignSystem.UseLogoDirective,
    fromDesignSystem.UseIllustrationDirective,
  ],
  template: `
    <img sdUseLogo="logo-minimal-filled" class="size-14" />
    <header class="flex flex-col gap-4 items-center text-center">
      <div class="*:typo-h4 tablet-portrait:*:typo-h3 *:font-semibold">
        <h1>{{ 'LOGIN.TITLE_LINE_1' | translate }}</h1>
        <div class="flex flex-row gap-3 items-center">
          <h1>
            {{ 'LOGIN.TITLE_LINE_2' | translate }}
          </h1>
          <img
            sdUseIllustration="bubble"
            class="size-10 hidden tablet-portrait:block"
          />
        </div>
      </div>
      <p class="typo-p1">{{ 'LOGIN.SUBTITLE' | translate }}</p>
    </header>
    <a
      sdButton
      variant="tonal"
      color="primary"
      class="w-full"
      (click)="handleClickLoginCTAContinueWithMicrosoft()"
    >
      <svg sdUseSocialIcon="microsoft-no-bg"></svg>
      <span class="truncate">{{
        'LOGIN.CTA_CONTINUE_WITH_MICROSOFT' | translate
      }}</span>
    </a>
  `,
  styles: `
    :host {
      @apply h-screen w-screen max-w-[480px] m-auto flex-col flex justify-center items-center p-10 gap-10;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  private readonly authStore = inject<Store<AuthState>>(Store);
  private readonly isAuthenticated = this.authStore.select(
    authFeature.selectIsAuthenticated
  );
  private readonly router = inject(Router);

  constructor() {
    /**
     * This make sure that as soon as the user is authenticated, we redirect
     * the user to the protected page. Thus, this one is not accessible by
     * authenticated users.
     */
    this.isAuthenticated
      .pipe(
        takeUntilDestroyed(),
        switchMap(async (isAuthenticated) => {
          if (isAuthenticated) {
            await this.router.navigate(['/chats']);
          }
        })
      )
      .subscribe();
  }

  handleClickLoginCTAContinueWithMicrosoft() {
    this.authStore.dispatch(AuthActions.login());
  }
}
